<template>
  <div>
    <b-card>
      <div
        class="my-2"
      >
        <b-alert
          variant="warning"
          show
        >
          <div class="alert-body">
            <span>*Los montos mostrados son calculos aproximados.</span>
          </div>
        </b-alert>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :row-style-class="rowStyleClassFn"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Common -->
            <span
              v-if="props.column.field !== 'payingDate'"
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
            <span
              v-else
            >
              {{ formatDate(props.row.payingDate) }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Mostrando grupos de
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> de {{ props.total }} registro(s) </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
      <b-col>
        <b-row
          class="justify-content-end mt-2"
        >
          <b-col
            md="auto"
            class="table-responsive-md p-0"
          >
            <b-table-simple
              small
              bordered
              responsive
              class="w-100"
            >
              <b-tbody>
                <b-tr>
                  <b-th
                    style="width: auto"
                  >
                    ITF Impuesto a la Transacciones Financieras Entrada
                  </b-th>
                  <b-td
                    class="text-right"
                    style="width: 1%; white-space: nowrap;"
                  >
                    {{ lTable[0] }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th
                    style="width: auto"
                  >
                    ITF Impuesto a la Transacciones Financieras Salida
                  </b-th>
                  <b-td
                    class="text-right"
                    style="width: 1%; white-space: nowrap;"
                  >
                    {{ lTable[1] }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th
                    style="width: auto"
                  >
                    Comisión de Cobranzas X {{ bills.length - 1 }} documento(s)
                  </b-th>
                  <b-td
                    class="text-right"
                    style="width: 1%; white-space: nowrap;"
                  >
                    {{ lTable[2] }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th
                    style="width: auto"
                  >
                    Gestión de Inscripción
                  </b-th>
                  <b-td
                    class="text-right"
                    style="width: 1%; white-space: nowrap;"
                  >
                    {{ lTable[3] }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th
                    style="width: auto"
                  >
                    Costo de Transferencia
                  </b-th>
                  <b-td
                    class="text-right"
                    style="width: 1%; white-space: nowrap;"
                  >
                    {{ lTable[4] }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th
                    style="width: auto"
                  >
                    IGV
                  </b-th>
                  <b-td
                    class="text-right"
                    style="width: 1%; white-space: nowrap;"
                  >
                    {{ lTable[5] }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th
                    variant="light"
                    class="text-right"
                    style="width: auto"
                  >
                    Sub Total
                  </b-th>
                  <b-td
                    variant="light"
                    class="text-right"
                    style="width: 1%; white-space: nowrap;"
                  >
                    {{ moneyFormat(lTable[6]) }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th
                    variant="dark"
                    class="text-right"
                    style="width: auto"
                  >
                    Desembolso Neto Aprox.*
                  </b-th>
                  <b-td
                    variant="dark"
                    class="text-right"
                    style="width: 1%; white-space: nowrap;"
                  >
                    {{ moneyFormat(lTable[7]) }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th
                    variant="light"
                    style="width: auto"
                  >
                    El fondo de garantia se devuelve a la fecha de cancelacion de la factura Negociada
                  </b-th>
                  <b-td
                    variant="light"
                    class="text-right"
                    style="width: 1%; white-space: nowrap;"
                  >
                    {{ moneyFormat(lTable[8]) }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
        <b-row
          class="justify-content-end"
        >
          <b-button
            variant="outline-secondary"
            class="mx-2"
            :to="{name:'Receipts-to-send-list'}"
          >
            Volver
          </b-button>
          <b-button
            variant="primary"
            @click="sendReceipts"
          >
            Enviar solicitud(es)
          </b-button>
        </b-row>
      </b-col>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BPagination, BFormSelect, BButton, BCard, BRow, BCol, BTableSimple, BTr, BTh, BTd, BTbody, BAlert,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormSelect,
    BButton,
    BCard,
    BRow,
    BCol,
    BTableSimple,
    BTr,
    BTh,
    BTd,
    BTbody,
    BAlert,
  },
  data() {
    return {
      userEmail: JSON.parse(localStorage.getItem('userData')).email,
      userRuc: JSON.parse(localStorage.getItem('userData')).ruc,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Cliente',
          field: 'clientData.businessName',
        },
        {
          label: 'Tipo de Doc',
          field: 'documentType',
        },
        {
          label: 'N° de documento',
          field: 'documentNumber',
        },
        {
          label: 'Importe de pago',
          field: 'originalAmount',
          tdClass: 'text-right',
          type: 'number',
          formatFn: this.moneyFormat,
        },
        {
          label: 'Fecha de pago',
          field: 'payingDate',
          tdClass: 'text-center',
        },
        {
          label: 'Plazo',
          field: 'payingTerm',
        },
        {
          label: 'Importe garantía EFIDE',
          field: 'amount',
          tdClass: 'text-right',
          type: 'number',
          formatFn: this.moneyFormat,
        },
        {
          label: 'Valor negociable',
          field: 'negotiableValue',
          tdClass: 'text-right',
          type: 'number',
          formatFn: this.moneyFormat,
        },
        {
          label: 'Interés descuento',
          field: 'discountFee',
          tdClass: 'text-right',
          type: 'number',
          formatFn: this.moneyFormat,
        },
        {
          label: 'Importe valorizado',
          field: 'valuedAmount',
          tdClass: 'text-right',
          type: 'number',
          formatFn: this.moneyFormat,
        },
      ],
      rows: [],
      bills: [],
      lTable: [0, 0, 35, 70, 10, 0, 0, 0, 0],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.rows = JSON.parse(localStorage.getItem('simulator'))
    this.bills = this.rows
    let negotiableValue = 0
    let discountFee = 0
    for (let index = 0; index < this.rows.length; index += 1) {
      this.lTable[0] += parseFloat(this.rows[index].originalAmount)
      this.lTable[1] += parseFloat(this.rows[index].valuedAmount)
      this.lTable[8] += parseFloat(this.rows[index].amount)
      negotiableValue += parseFloat(this.rows[index].negotiableValue)
      discountFee += parseFloat(this.rows[index].discountFee)
    }
    this.rows.push(
      {
        id: 'last',
        originalAmount: this.lTable[0],
        valuedAmount: this.lTable[1],
        amount: this.lTable[8],
        negotiableValue,
        discountFee,
        payingDate: '',
      },
    )
    const sum = this.lTable[1]
    const lTable0 = parseFloat(this.lTable[0]) * (0.005 / 100)
    const lTable1 = parseFloat(this.lTable[1]) * (0.005 / 100)
    const lTable2 = parseFloat(this.lTable[2]) * (this.rows.length - 1)
    const lTable5 = (parseFloat(lTable2) + parseFloat(this.lTable[3]) + parseFloat(this.lTable[4])) * (0.18)
    const lTable6 = lTable0 + lTable1 + lTable2 + parseFloat(this.lTable[3]) + parseFloat(this.lTable[4]) + lTable5
    this.lTable[0] = lTable0.toFixed(2)
    this.lTable[1] = lTable1.toFixed(2)
    this.lTable[2] = lTable2.toFixed(2)
    this.lTable[5] = lTable5.toFixed(2)
    this.lTable[6] = lTable6.toFixed(2)
    this.lTable[7] = (parseFloat(sum) - lTable6).toFixed(2)
    this.lTable[8] = this.lTable[8].toFixed(2)
  },
  methods: {
    rowStyleClassFn(row) {
      return row.id === 'last' ? 'table-footer' : ''
    },
    moneyFormat(money) {
      return 'S/'.concat(' ', Number.parseFloat(money).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
    },
    formatDate(date) {
      if (date === '') return ''
      const newDate = new Date(date)
      const dateArr = []
      // dateArr.push(newDate.getFullYear().toString(), (newDate.getMonth() + 1).toString(), newDate.getDate().toString())
      dateArr.push(newDate.getDate().toString().padStart(2, '0'), (newDate.getMonth() + 1).toString().padStart(2, '0'), newDate.getFullYear().toString())
      return dateArr.join('/')
    },
    sendReceipts() {
      const selected = this.rows
      let flag = true
      for (let index = 0; index < selected.length; index += 1) {
        if (selected[index].differenceDay < 0) {
          flag = false
        }
      }

      if (flag === false) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Solicitudes vencidas',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'No se pueden enviar solicitudes con fecha de pago vencido',
          },
        },
        {
          timeout: 8000,
        })
      } else {
        useJwt.sendMyReceiptsToSend({
          ruc: this.userRuc,
          selected,
        })
          .then(() => {
            this.$router.replace('/cliente/panel')
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Éxito',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Se ha(n) enviado las solicitud(es) con éxito.',
                  },
                },
                {
                  timeout: 8000,
                })
              })
          })
          .catch(error => {
            console.log('errores', error)
          })
      }
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card .card-body {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}

</style>
